<template>
	<div></div>
</template>

<script>
export default {
	mounted() {
		const params = {}
		location.href.split('transfers?')[1]
			.split('&')
			.forEach(item => {
				const a = item.split('=')
				params[a[0]] = a[1] || null
			})
		window.opener.getCode(params)
	}
}
</script>

<style>

</style>